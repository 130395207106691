module.exports={
    "PLATFORM_ID": "geotrek-randotectec",
    "API_URL": "https://randotectec.reunion-parcnational.fr/data",
    "BACKOFFICE_URL": "",

    "LOGO_FILE": "logo_pnrun_cartouche.png",
    "APPROVED_BIG": "/custom/images/epn.jpg",
    "APPROVED_SMALL": "/custom/images/epn.jpg",
    "APPROVED_LABEL": "Esprit Parc National",

    "GOOGLE_ANALYTICS_ID": "UA-90108040-2",
    "FACEBOOK_APP_ID": "144889572791990",

    "FAVICON":{
        "png": "favicon.png"
    },
	
    "HOME_TEMPLATE_FILE": {
	"fr": "custom-home-fr.html"
    },	

    "HEADER_TEMPLATE_FILE" : "custom-header.html",

    "ENABLE_HTML_MODE": true,
    "ENABLE_DISTRICTS_FILTERING": true,
    "ENABLE_CITIES_FILTERING": true,
    "ENABLE_STRUCTURE_FILTERING": false,
    "GEO_FILTERS_AUTO_CLOSE": true,

    "DEFAULT_LANGUAGE": "fr",
    "AVAILABLE_LANGUAGES": {
        "fr": "Français"
    },
    "ENABLED_LANGUAGES": ["fr"],

    "ENABLE_TREKS": true,
    "ENABLE_TOURISTIC_CONTENT": true,
    "ENABLE_TOURISTIC_EVENTS": false,
    "DEFAULT_ACTIVE_CATEGORIES": ["T"],
    "ENABLE_UNIQUE_CAT": false,
    "FILTERS_DEFAULT_OPEN": true,
    "DEFAULT_INTEREST": "pois",
    "RANDOMIZE_RESULTS": false,
    "CENTERON_FITS_BOUNDS": true,
	
    "PERMANENT_TILELAYERS_NAME": "Carte",
    "PERMANENT_TILELAYERS": [
        {
	    "LAYER_URL": "https://wxs.ign.fr/7oz2vv1fl9d75xvcxuw63uqv/geoportail/wmts?LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
            "OPTIONS": {
                "attribution": "(c) IGN Geoportail",
                "minZoom": 9,
                "maxZoom": 18
            }
        }
    ],
	
    "ORTHOPHOTO_TILELAYERS_NAME": "Ortho",
    "ORTHOPHOTO_TILELAYERS": {
	 "LAYER_URL": "https://wxs.ign.fr/7oz2vv1fl9d75xvcxuw63uqv/geoportail/wmts?LAYER=ORTHOIMAGERY.ORTHOPHOTOS&EXCEPTIONS=text/xml&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
	"OPTIONS": {
            "id": "satellite",
	    "attribution": "(c) IGN Geoportail"
        }		
    },

    "LEAFLET_CONF": {
        "SRID": 3857,
	"CENTER_LATITUDE": -21.11238,
        "CENTER_LONGITUDE": 55.48248,
        "DEFAULT_ZOOM": 14,
        "DEFAULT_MIN_ZOOM": 9,
        "DEFAULT_MAX_ZOOM": 18
    },

    "MAP_BOUNDS_CONSTRAINTS": null,
    "TREKS_TO_GEOJSON_ZOOM_LEVEL" : 14,
    "UPDATE_MAP_ON_FILTER": true,
    "ACTIVE_MINIMAP": false,
	
    "ALWAYS_HIGHLIGHT_TREKS": true,
    "SHOW_FILTERS_ON_MAP": true,
    "FILTER_BY_VIEWPORT_DEFAULT": true,
	
    "POI_EXPANDED": false,
    "NEAR_ELEMENTS_CATEGORIES": false,
    "DISPLAY_ASIDES_COUNTERS": true,
    "SHOW_ARROWS_ON_ROUTE": true,
	
    "DURATION_FILTER": [
        { "id": 0, "label": "< 4h"},
        { "id": 4, "label": " 4h"},
        { "id": 8, "label": " 8h"},
        { "id": 999999, "label": "> 8h"}
    ],
    "ASCENT_FILTER":  [
        { "id": 0, "label": "< 300m"},
        { "id": 300, "label": " 300m"},
        { "id": 600, "label": " 600m"},
        { "id": 1000, "label": " 1000m"},
        { "id": 999999, "label": "> 1000m"}
    ],
    "LENGTH_FILTER":  [
        { "id": 0, "label": "< 5km"},
        { "id": 5000, "label": " 5km"},
        { "id": 10000, "label": " 10km"},
        { "id": 20000, "label": " 20km"},
        { "id": 9999999, "label": "> 20km"}
    ]
}
